<template>
  <div class="d-flex full-height">
    <computed-document-ref
      v-if="value && value.length === 1 && value[0].length === 1"
      :id="value[0][0].id"
      :documentType="value[0][0].type"
      :options="computedOptions"
      :account-id="accountId"
      :show-icon="true"
      :icon-mark-props="getIconMarkProps(value[0][0].extra)"
      :extra="value[0][0].extra"
    />
    <div
      class="d-flex align-center"
      v-else-if="value && value.length"
      @click.stop
    >
      <v-tooltip
        top
        open-delay="1000"
        v-for="a in value"
        :key="a[0].id"
      >
        <template v-slot:activator="{ on }">
          <div v-on="on">
            <document-type-badge
              :icon-mark-props="getIconMarkProps(a[0].extra)"
              :type="a[0].type"
              :ids="a.map(v => v.id)"
              class="mr-3"
              :icon-props="iconProps"
            />
          </div>
        </template>
        <span>{{$t(typeName(a[0].type, a[0].extra))}}</span>
      </v-tooltip>
    </div>
    <v-icon v-else-if="column.options.showNoneIcon">{{$icon('i.None')}}</v-icon>
  </div>
</template>

<script>
import Column from '@/pages/search/controllers/column'

export default {
  inheritAttrs: false,
  components: {
    DocumentTypeBadge: () => import('@/components/document-type-badge'),
    ComputedDocumentRef: () => import('@/components/documents-ref/computed-document-ref')
  },
  data () {
    return {
      listElement: undefined
    }
  },
  mounted () {
    this.$nextTick(() => { this.listElement = this.$el.closest('content .documents-by-type,aside .v-navigation-drawer__content') })
  },
  computed: {
    computedOptions () {
      return Object.assign({}, this.column.options, { hideNavigation: false })
    },
    computedValue () {
      return this.lodash.groupBy(this.value, 'type')
    }
  },
  methods: {
    getIconMarkProps (extra) {
      return {
        isStrike: extra
      }
    },
    typeName (type, extra) {
      if (!type) { return }

      switch (type) {
        case 'promises': {
          if (extra) {
            return 't.BrokenPromises'
          } else {
            return `t.${this.toPascalCase(type)}`
          }
        }
        default: return `t.${this.toPascalCase(type)}`
      }
    }
  },
  props: {
    value: {
      type: Array
    },
    column: Column,
    accountId: String,
    iconProps: Object
  }
}
</script>

<style lang="stylus" scoped>
.full-height
  height 100%
</style>
